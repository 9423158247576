import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    const [isOpen, setIsOpen] = useState(false);

    const linkBlock = <div className={`flex flex-col md:flex-row md:space-x-3 mt-2 md:mt-0 md:ml-5 md:items-center ${isOpen ? 'block' : 'hidden'} md:block gap-2`}>
        <Link to="information" className="nav-link" onClick={() => setIsOpen(false)}>Information</Link>
        <Link to="photos" className="nav-link" onClick={() => setIsOpen(false)}>Photos</Link>
        <Link to="video" className="nav-link" onClick={() => setIsOpen(false)}>Video</Link>
        <Link to="equipe" className="nav-link" onClick={() => setIsOpen(false)}>Équipe</Link>
        <Link to="portfolio" className="nav-link" onClick={() => setIsOpen(false)}>Réalisation</Link>
        <Link to="contact" className="nav-link" onClick={() => setIsOpen(false)}>Contact</Link>
    </div>;

    return (
        <nav class="flex items-center justify-between flex-wrap p-6">
            <Link to="/" class="font-semibold text-xl tracking-tight text-black" onClick={() => setIsOpen(false)}>Les maisons de l'ile</Link>
            <div class="block md:hidden">
                <button class="flex items-center px-3 py-2 border rounded hover:text-slate-900 hover:border-slate-900" onClick={() => setIsOpen(!isOpen)}>
                    <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>
            <div class="w-full block flex-grow md:flex lg:items-center md:w-auto">
                {linkBlock}
            </div>
        </nav>
    );
}

export default Navigation;
