import React from 'react';
import marcel from '../assets/marcel.jpeg';
import sylvie from '../assets/sylvie.jpeg';

function Equipe() {
  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <div className='flex flex-col sm:flex-row gap-0 md:gap-5'>
          <img src={marcel} alt="marcel gendron" className='object-scale-down' />
          <div>
            <h1 className='text-2xl px-5'>
              Marcel Gendron, Entrepreneur
            </h1>
            <p className='px-5'>
              Homme d’affaires depuis plus de 25 ans. Bâtisseur dans l'âme, Marcel n’a jamais froid aux yeux et est toujours à la recherche de nouveaux défis à relever (du plus petit au plus grand). Il s’applique toujours de façon exemplaire pour satisfaire sa clientèle.
            </p>
          </div>
        </div>
      </div>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <div className='flex flex-col sm:flex-row gap-0 md:gap-5'>
          <img src={sylvie} alt="sylvie gendron" className='object-scale-down' />
          <div>
            <h1 className='text-2xl px-5'>
              Sylvie Gendron, Comptabilité
            </h1>
            <p className='p-5'>
              Travaillant avec le public depuis ses débuts, Sylvie s’occupe maintenant de la comptabilité pour nos entreprises familiales depuis près de vingt ans. Elle est toujours prête pour un nouveau projet, et ce, peu importe le nombre d’heures nécessaire.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Equipe;
