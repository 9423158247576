import React from 'react';
import ImageGallery from 'react-image-gallery';
import gallery1 from '../assets/realisation1.jpeg';
import gallery2 from '../assets/realisation2.jpeg';
import gallery3 from '../assets/realisation3.jpeg';
import gallery4 from '../assets/realisation4.jpeg';
import gallery5 from '../assets/realisation5.jpeg';
import gallery6 from '../assets/realisation6.jpeg';
import gallery7 from '../assets/realisation7.jpeg';
import gallery8 from '../assets/realisation8.jpeg';
import gallery9 from '../assets/realisation9.jpeg';
import gallery10 from '../assets/realisation10.jpeg';
import gallery11 from '../assets/realisation11.png';
import gallery12 from '../assets/realisation12.png';

const images = [
  {
    original: gallery1,
    thumbnail: gallery1,
  },
  {
    original: gallery2,
    thumbnail: gallery2,
  },
  {
    original: gallery3,
    thumbnail: gallery3,
  },
  {
    original: gallery4,
    thumbnail: gallery4,
  },
  {
    original: gallery5,
    thumbnail: gallery5,
  },
  {
    original: gallery6,
    thumbnail: gallery6,
  },
  {
    original: gallery7,
    thumbnail: gallery7,
  },
  {
    original: gallery8,
    thumbnail: gallery8,
  },
  {
    original: gallery9,
    thumbnail: gallery9,
  },
  {
    original: gallery10,
    thumbnail: gallery10,
  },
  {
    original: gallery11,
    thumbnail: gallery11,
  },
  {
    original: gallery12,
    thumbnail: gallery12,
  },
];

function Portfolio() {
  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Réalisation passée</h1>
        <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />
      </div>
    </div>
  );
}

export default Portfolio;
