import React from 'react';
import objectif from '../assets/concept.jpeg';
import phase1outside from '../assets/phase1.jpeg';
import phase1inside from '../assets/configuration1.jpeg';
import phase2outside from '../assets/phase2.jpeg';
import phase2inside from '../assets/configuration2.png';
import plandirecteur from '../assets/plandirecteur.jpeg';

function Information() {
  return (
    <div className='w-full lg:w-3/4 mx-auto'>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Objectif</h1>
        <div className='flex flex-col md:flex-row gap-5'>
          <p className='text-justify'>
            Implanter un milieu de vie résidentiel de haute qualité sur les rives du Richelieu.
            S'endormir au bruit de l'eau c'est romantique, y pratiquer ses sports nautiques et
            hivernaux c'est magique. Que vous soyez jeune ou moins jeune, vous vivrez en
            harmonie avec la nature tout au long de l'année. Bienvenue à Saint-Paul-de-l'ile-Aux-Noix,
            Capitale nautique.
          </p>
          <img src={objectif} alt="concept" className="w-full md:w-1/2" />
        </div>
      </div>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Plan directeur</h1>
        <img src={plandirecteur} alt="concept" className="w-full" />
      </div>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Phase 1</h1>
        <img src={phase1outside} alt="concept" className="w-full" />
        <img src={phase1inside} alt="concept" className="w-full" />
      </div>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Phase 2</h1>
        <img src={phase2outside} alt="concept" className="w-full" />
        <img src={phase2inside} alt="concept" className="w-full" />
      </div>
    </div>
  );
}
export default Information;
