import React from 'react';
import ImageGallery from 'react-image-gallery';
import gallery1 from '../assets/gallery1.jpeg';
import gallery2 from '../assets/gallery2.jpeg';
import gallery3 from '../assets/gallery3.jpeg';
import gallery4 from '../assets/gallery4.jpeg';
import gallery5 from '../assets/gallery5.jpeg';
import gallery6 from '../assets/gallery6.jpeg';
import gallery7 from '../assets/gallery7.jpeg';
import gallery8 from '../assets/gallery8.jpeg';
import gallery9 from '../assets/gallery9.jpeg';
import gallery10 from '../assets/gallery10.jpeg';
import gallery11 from '../assets/gallery11.jpeg';
import gallery12 from '../assets/gallery12.jpeg';
import gallery13 from '../assets/gallery13.jpeg';
import gallery14 from '../assets/gallery14.jpeg';
import gallery15 from '../assets/gallery15.jpeg';
import gallery16 from '../assets/gallery16.jpeg';
import gallery17 from '../assets/gallery17.jpeg';
import gallery18 from '../assets/gallery18.jpeg';
import gallery19 from '../assets/gallery19.jpeg';
import gallery20 from '../assets/gallery20.jpeg';
import gallery21 from '../assets/gallery21.jpeg';
import gallery22 from '../assets/gallery22.jpeg';
import gallery23 from '../assets/gallery23.jpeg';
import gallery24 from '../assets/gallery24.jpeg';
import gallery25 from '../assets/gallery25.jpeg';
import gallery26 from '../assets/gallery26.jpeg';
import gallery27 from '../assets/gallery27.jpeg';

const images = [
  {
    original: gallery1,
    thumbnail: gallery1,
  },
  {
    original: gallery2,
    thumbnail: gallery2,
  },
  {
    original: gallery3,
    thumbnail: gallery3,
  },
  {
    original: gallery4,
    thumbnail: gallery4,
  },
  {
    original: gallery5,
    thumbnail: gallery5,
  },
  {
    original: gallery6,
    thumbnail: gallery6,
  },
  {
    original: gallery7,
    thumbnail: gallery7,
  },
  {
    original: gallery8,
    thumbnail: gallery8,
  },
  {
    original: gallery9,
    thumbnail: gallery9,
  },
  {
    original: gallery10,
    thumbnail: gallery10,
  },
  {
    original: gallery11,
    thumbnail: gallery11,
  },
  {
    original: gallery12,
    thumbnail: gallery12,
  },
  {
    original: gallery13,
    thumbnail: gallery13,
  },
  {
    original: gallery14,
    thumbnail: gallery14,
  },
  {
    original: gallery15,
    thumbnail: gallery15,
  },
  {
    original: gallery16,
    thumbnail: gallery16,
  },
  {
    original: gallery17,
    thumbnail: gallery17,
  },
  {
    original: gallery18,
    thumbnail: gallery18,
  },
  {
    original: gallery19,
    thumbnail: gallery19,
  },
  {
    original: gallery20,
    thumbnail: gallery20,
  },
  {
    original: gallery21,
    thumbnail: gallery21,
  },
  {
    original: gallery22,
    thumbnail: gallery22,
  },
  {
    original: gallery23,
    thumbnail: gallery23,
  },
  {
    original: gallery24,
    thumbnail: gallery24,
  },
  {
    original: gallery25,
    thumbnail: gallery25,
  },
  {
    original: gallery26,
    thumbnail: gallery26,
  },
  {
    original: gallery27,
    thumbnail: gallery27,
  },
];

function Photos() {
  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Gallerie photo</h1>
        <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />
      </div>
    </div>
  );
}

export default Photos;
