import React from 'react';
import YouTube from 'react-youtube';

function Video() {
  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <h1 className='text-2xl mb-5'>Video promotionnelle</h1>
        <YouTube videoId='JM5K_-fHeGg' opts={opts} />
      </div>
    </div>
  );
}

export default Video;
