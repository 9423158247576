import React from 'react';
import image1 from '../assets/banner1.jpeg';
import image2 from '../assets/banner2.jpeg';
import image3 from '../assets/banner3.jpeg';
import image4 from '../assets/banner4.jpeg';

import { useLocation } from 'react-router-dom';

function Banner() {
  const location = useLocation();
  const currentUrl = location.pathname;

  let imageURL = '';

  // Really not the best way to do this, but it works for now.
  if (currentUrl === '/information') {
    imageURL = image2;
  } else if (currentUrl === '/photos') {
    imageURL = image3;
  } else if (currentUrl === '/video') {
    imageURL = image3;
  } else if (currentUrl === '/equipe') {
    imageURL = image4;
  } else if (currentUrl === '/realisation') {
    imageURL = image4;
  } else if (currentUrl === '/contact') {
    imageURL = image4;
  } else {
    imageURL = image1;
  }

  return (
    <div style={{ height: '450px', backgroundImage: `url(${imageURL})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
  );
}

export default Banner;
