import React from 'react';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='w-full lg:w-3/4 mx-auto px-4 justify-center flex items-center flex-col m-5 mb-12'>
      <PageTitle title="Nous nous donnons comme mission de réaliser votre rêve de vivre sur le bord de l’eau. Que ce soit pour la pêche sur la glace ou pour passer vos week-ends en bateau, nos habitations de haute qualité répondront à tous vos besoins." subtext="Notre projet immobilier est situé dans le village de Saint-Paul-De-L'Ile-Aux-Noix à 25 minutes du Pont Champlain et Pont Mercier." />
      <Link to="information" className="p-5 bg-black text-white mx-auto hover:bg-slate-800">J'aimerais plus d'information!</Link>
    </div>
  );
}

export default Home;
