import React from 'react';
import PageTitle from '../components/PageTitle';

function Erreur404() {
  return (
    <div className='w-full lg:w-3/4 mx-auto my-10'>
      <PageTitle title="Oups" subtext="Cette page n'existe pas." />
    </div>
  );
}

export default Erreur404;
