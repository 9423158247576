import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Erreur404 from './pages/Erreur404';
import Contact from './pages/Contact';
import Video from './pages/Video';
import Information from './pages/Information';
import Photos from './pages/Photos';
import Equipe from './pages/Equipe';
import Portfolio from './pages/Porfolio';
import './index.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="information" element={<Information />} />
          <Route path="photos" element={<Photos />} />
          <Route path="video" element={<Video />} />
          <Route path="equipe" element={<Equipe />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Erreur404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
