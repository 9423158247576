import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import PageTitle from '../components/PageTitle';

function Contact() {
  const [sent, setSent] = React.useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    console.log('test trying to send', form.current);

    emailjs.sendForm('service_ghmhj7l', 'template_maunc4y', form.current, 'MRn6neoRDWOQ1kiXp')
      .then((result) => {
        console.log(result.text);
        setSent(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  if (sent) {
    return (
      <div className='w-full lg:w-3/4 mx-auto rounded-lg px-4'>
        <PageTitle title="Vous avez des questions ?" subtext="Si vous avez des questions ou commentaires sur le projet, n’hésitez pas à utiliser le formulaire si dessous pour nous contacter." />
        <div className='w-[3/4] m-2 mt-5 p-5 bg-white rounded border text-center'>
          Sent
        </div>
      </div>

    );
  }

  return (
    <div className='w-full lg:w-3/4 mx-auto rounded-lg'>
      <PageTitle title="Vous avez des questions ?" subtext="Si vous avez des questions ou commentaires sur le projet, n’hésitez pas à utiliser le formulaire si dessous pour nous contacter." />
      <div className='text-left w-[3/4] m-2 mt-5 p-5 bg-white rounded border'>
        <form ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col">
            <label htmlFor="user_name" className='py-2'>Nom *</label>
            <input type="text" className="form-control border p-3" name="user_name" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="user_email" className='py-2'>Email*</label>
            <input type="email" className="form-control border p-3" name="user_email" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="message" className='py-2'>Message *</label>
            <textarea className="form-control border p-3" rows="5" name="message" ></textarea>
          </div>
          <button type="submit" className="bg-black text-white p-5 rounded-lg my-4" value="Send">Envoyer</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
