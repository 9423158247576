import React from 'react';

function PageTitle({ title, subtext }) {
    return (
        <div className='flex flex-col m-10 text-center mx-auto space-y-2'>
            <p className='font-semi text-3xl'>{title}</p>
            <p>{subtext}</p>
        </div>
    );
}

export default PageTitle;
