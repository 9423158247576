import React from 'react';
import image1 from '../assets/certification.png';

function Footer() {
  return (
    <div className='bg-zinc-800 text-white p-5 text-center flex flex-col gap-2'>
      <img src={image1} alt={`GCR logo`} className='mx-auto' />
      <p>Ce projet est certifié par GCR</p>
      <p>© Les Habitations Gendron</p>
    </div>
  );
}

export default Footer;
